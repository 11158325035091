<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.lottoResults') }}</h5>
                <DataTable
                    @page="onPage($event)"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    stateStorage="session"
                    stateKey="dt-state-lottoresults"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="lotteryModel" :options="lotteries" :placeholder="$t('dropdown.placeholder.lottery')" optionLabel="name" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-4 p-text-right"><Button :label="$t('buttons.showResults')" class="p-button-raised p-button-success" @click="readItems()" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="offerNumber" :header="$t('sportsbets.offerNo')" :sortable="true"></Column>
                    <Column field="date" :header="$t('lotto.date')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.date) }} </template></Column
                    >
                    <Column field="name" :header="$t('lotto.lotteryName')" :sortable="true"></Column>
                    <Column field="_id" :header="$t('lotto.drawId')">
                        <template #body="{ data }"><Button class="p-button p-button-text p-button-info" :label="data._id" @click="redirectToDrawTickets(data._id)"></Button></template>
                    </Column>
                    <Column field="ticketsNo" :header="$t('lotto.ticketsNo')" :sortable="true"> </Column>
                    <Column field="results" :header="$t('lotto.resultsAddDate')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.results) ? formatCSDateAndTime(data.results.addDate) : '' }} </template></Column
                    >
                    <Column field="results" :header="$t('lotto.resultsAddedBy')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.results) ? data.results.addedBy : '' }} </template></Column
                    >
                    <Column field="results" :header="$t('lotto.primeResults')">
                        <template #body="{ data }"> {{ getDrawResults(data.results, 'FIRST5') }} </template></Column
                    >
                    <Column field="results" :header="$t('lotto.machineResults')">
                        <template #body="{ data }"> {{ getDrawResults(data.results, 'LAST5') }} </template></Column
                    >
                    <Column field="results" :header="$t('lotto.extraNoResults')">
                        <template #body="{ data }"> {{ notNullOrEmptyObject(data.results) && notNullOrEmptyField(data.results.extraNo) ? data.results.extraNo.map((n) => n.number).join(',') : '' }} </template></Column
                    >
                    <Column field="autoResultsInfo" :header="$t('lotto.autoResultsInfo')"> </Column>
                    <Column :exportable="false" class="p-text-right" v-show="hasAccess(availableMenus, 'processTicket')">
                        <template #body="slotProps">
                            <Button v-if="slotProps.data.results != null" :label="$t('buttons.processTickets')" class="p-button-info p-button-text p-mr-2" @click="processTickets(slotProps.data._id)" />
                        </template>
                    </Column>
                    <Column :exportable="false" class="p-text-right" v-show="hasAccess(availableMenus, 'addLottoResults')">
                        <template #body="slotProps">
                            <Button :label="$t('buttons.changeResults')" v-if="slotProps.data.results != null" class="p-button-warning p-button-text p-mr-2" @click="goto('addLottoResults', { id: slotProps.data._id })" />

                            <Button :label="$t('buttons.addResults')" v-else class="p-button-success p-button-text p-mr-2" @click="goto('addLottoResults', { id: slotProps.data._id })" />
                        </template>
                    </Column>
                </DataTable>
                <Dialog :header="$t('general.success')" v-model:visible="showOkAlert" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ validatedTickets }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="close" icon="pi pi-check" class="p-button-secondary" />
                        <Button :label="$t('buttons.showTickets')" @click="redirectToResultsTickets()" icon="pi pi-check" class="p-button-primary" />
                    </template>
                </Dialog>
                <Dialog :header="$t('general.error')" v-model:visible="showErrorAlert" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p>
                        {{ $t('alerts.generalError') }}
                    </p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="close" icon="pi pi-check" class="p-button-secondary" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import { BoLottoApi } from '../../service/BoLottoApi';
import { LottoApi } from '../../service/LottoApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
export default {
    name: 'LottoResults',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoLottoApi: new BoLottoApi(),
            LottoApi: new LottoApi(),
            sort: '',
            crtPage: 0,
            startDateModel: null,
            endDateModel: null,
            startDateString: '',
            endDateString: '',
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            lotteries: [],
            lotteryModel: { name: 'All', id: 0, code: 0 },
            crtRoute: 'lottoResults',
            showOkAlert: false,
            showErrorAlert: false,
            validatedTickets: '',
        };
    },
    computed: {
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
    },
    watch: {},
    mounted() {
        this.LottoApi.getLotteriesForFilter().then((response) => {
            this.lotteries = response.data;
            if (this.storeFilterData != undefined) {
                this.startDateModel = new Date(this.storeFilterData.data.startDate);
                this.endDateModel = new Date(this.storeFilterData.data.endDate);
                let storeLottery = this.lotteries.find((s) => s.id == this.storeFilterData.data.lottery);
                if (this.notNullOrEmptyObject(storeLottery)) {
                    this.lotteryModel = storeLottery;
                }
                this.readItems(this.storeFilterData.data.page);
            } else {
                let crtDate = new Date();
                let year = crtDate.getFullYear();
                let month = crtDate.getMonth() + 1;
                if (month <= 9) {
                    month = '0' + month;
                }
                let day = crtDate.getDate();
                if (day <= 9) {
                    day = '0' + day;
                }
                this.startDateString = year + '-' + month + '-' + day + 'T00:00';
                this.endDateString = year + '-' + month + '-' + day + 'T23:59';
                this.startDateModel = new Date(this.startDateString);
                this.endDateModel = new Date(this.endDateString);
                this.readItems();
            }
        });
    },
    methods: {
        composeAndSaveFiltersToStore() {
            let filters = {
                startDate: this.startDateModel,
                endDate: this.endDateModel,
                lottery: this.lotteryModel.id,
                page: this.crtPage,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            var startDate = this.formatEnDate(this.startDateModel.toString());
            var endDate = this.formatEnDate(this.endDateModel.toString());
            this.LottoApi.getResults(startDate, endDate, this.lotteryModel.code)
                .then((response) => {
                    this.composeAndSaveFiltersToStore();
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        getDrawResults(results, drawName) {
            if (this.notNullOrEmptyObject(results) && this.notNullOrEmptyObject(results.draws)) {
                for (var i = 0; i < results.draws.length; i++) {
                    if (results.draws[i].drawName == drawName) {
                        return results.draws[i].drawNo.join(',');
                    }
                }
            }
        },
        processTickets(drawId) {
            this.selectedDraw = drawId;
            this.BoLottoApi.processLottoTickets(drawId)
                .then((response) => {
                    this.showOkAlert = true;
                    this.validatedTickets = response.data.message;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showErrorAlert = true;
                });
        },
        redirectToResultsTickets() {
            this.goto('lottoTickets', { drawIdParam: this.selectedDraw });
        },
        redirectToDrawTickets(drawId) {
            this.goto('lottoTickets', { drawIdParam: drawId });
        },
        close() {
            this.showOkAlert = false;
            this.showErrorAlert = false;
        },
    },
};
</script>
